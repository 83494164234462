import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Button, Rating, Avatar } from '@mui/material';
import axios from 'axios';
import swal from 'sweetalert';
import { useCart } from '../../../../contexts/CartContext';
import { useAuth } from '../../../../contexts/AuthContext';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';

import PreviewIcon from '@mui/icons-material/Preview';
import PreviewModalDialog from '../../PreviewModalDialog';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

function BankPo() {
  const { isAuthenticated, checkUserAuth } = useAuth();
  const [papers, setPapers] = useState(false);
  const [loading, setLoading] = useState(false);
  const { addToCart, isItemInCart, addToWishlist, removeFormWishlist } = useCart();
  const [paper, setPaper] = useState([]);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setTimeout(() => {
      fetchData();
    }, 5000);
  }, [isAuthenticated]);

  /**
   * Fetching data from server.
   */
  const fetchData = async () => {
    try {
      var api_url = '/api/frontend/get-bank-op';
      if (isAuthenticated) {
        api_url = '/api/frontend/get-auth-bank-op';
      }
      axios.post(api_url)
        .then(response => {
          if (response.data.response_code === 200) {
            setPapers(response.data.data);
          } else if (response.data.response_code === 401) {

          } else {

          }
          setLoading(false);
        })
        .catch(function (error) {
          setLoading(false);
          console.log(error);
        });
    } catch (e) {
      setLoading(false);
    }
  }

  /**
   * Add Product in to wishlist
   */
  const productAddToWishlist = (paper) => {
    if (checkUserAuth()) {
      addToWishlist(paper.id);
      const newPapers = papers.map((item, i) => {
        if (paper.id === item.id) {
          return { ...item, ['in_wishlist']: 1 };
        } else {
          return item;
        }
      });
      setPapers(newPapers);
    } else {
      swal({
        text: 'Please logged out from current login and Signin or Signup as student or user for Add To Wishlist!',
        icon: 'warning',
        buttons: false,
        timer: 2000
      });
      return false;
    }
  }

  /**
   * Remove Product from to wishlist
   */
  const productRemoveFormWishlist = (paper) => {
    removeFormWishlist(paper.id);
    const newPapers = papers.map((item, i) => {
      if (paper.id === item.id) {
        return { ...item, ['in_wishlist']: 0 };
      } else {
        return item;
      }
    });
    setPapers(newPapers);
  }

  const permalink = (row) => {
    var subject = row.subject_name;
    var subject_slug = (row.subject_slug) ? row.subject_slug : subject.replace(' ', '-').toLowerCase();
    if (row.type === 'practice') {
      var url = `/samplepaper/paper-detail/${subject_slug}/${row.slug}`;
    } else {
      var url = `/notes/paper-detail/${subject_slug}/${row.slug}`;
    }
    return url;
  }

  // Product Preview
  const visiblityPreviewModal = (rowData) => {
    setPaper(rowData)
    setPreviewModalOpen(true);
  };

  return (
    <>
      {papers.length ? (
        <div className="section">
          <div className="container sctnheader">
            <h3 className="btn-shine">Latest notes and sample papers for Bank PO</h3>
            <Button href="search?q=&selected_organization=4&selected_competitive_exam=112" variant="contained" color="secondary" className='view-all-btn'>View All</Button>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="row row-cols-1 row-cols-lg-5 product_slider procolt product_list_wrap" >
                  {papers && (
                    papers.map((row, index) => (
                      <div className="item col" key={index}>
                        <div className="product">
                          <Link to={permalink(row)} title={row.topic_title}>
                            <div className="product_img">
                              <img width={'254px'} height={'219px'} src={(row.thumb_nail_image ?? row.thumb_nail_url)} alt={row.topic_title} />
                            </div>
                          </Link>

                          <div className="product_info">
                            <div className='author-info-wrap'>
                              <h6 className='product-subject'>
                                <div className='auther-profile'>
                                  {row.profile_image ?
                                    <img width={'50px'} height={'50px'} src={row.profile_image} alt={row.author_name} />
                                    :
                                    <Avatar src="/broken-image.jpg" />
                                  }
                                </div>
                                <Link to={permalink(row)} title={row.subject_name}>
                                  {row.subject_name.split(' ')[0]}{(row.subject_name.split(' ')[1]) ? '...' : ''}
                                </Link>

                                <small>{row.author_name}</small>
                              </h6>
                            </div>
                            <h6 className="product_title">
                              <Link to={permalink(row)} title={row.topic_title}>
                                {row.topic_title.replace(/(.{80})..+/, "$1…")}
                              </Link>
                            </h6>

                            <div className="rating_wrap">
                              <span className="product_title">
                                {(() => {
                                  switch (row.organization_type_id) {
                                    case (1 || '1'):
                                      return `${row.class_name.replace(/(.{30})..+/, "$1…")}`;
                                    case (2 || '2'):
                                      return `${row.university_name.replace(/(.{20})..+/, "$1…")}`;
                                    case (3 || '3'):
                                      return `${row.entrance_exam_name.replace(/(.{20})..+/, "$1…")}`;
                                    case (4 || '4'):
                                      return `${row.competitive_exam_name.replace(/(.{20})..+/, "$1…")}`;
                                    default:
                                      return null;
                                  }
                                })()}
                              </span>
                              <br />
                              <div className="rating">
                                <span className="rating_num">({row.total_review})</span>
                                <Rating className="rating" value={row.avg_rating} name="size-large" size="large" readOnly />
                              </div>
                              {isAuthenticated && row.in_wishlist ? (
                                <Button color='secondary' aria-label="Name" onClick={() => productRemoveFormWishlist(row)} size='small' style={{ float: "right" }}>
                                  <FavoriteIcon></FavoriteIcon>
                                </Button>
                              ) : (
                                <Button color='secondary' aria-label="Name" onClick={() => productAddToWishlist(row)} size='small' style={{ float: "right" }}>
                                  <FavoriteBorderIcon></FavoriteBorderIcon>
                                </Button>
                              )}
                            </div>
                            <div className="prdt_price">
                              <div>
                                <small className="price1" style={{ textDecoration: "line-through" }}>{process.env.REACT_APP_CURRENCY} {row.gst_price}</small>
                                <small>{row.discount_percentage ? ` ( -${row.discount_percentage}%)` : ' ( -0%)'}</small>
                                {/* <small>{` (Inclusive GST)`}</small> */}
                              </div>
                              {(row.price) ? (
                                <>
                                  <span className="price">
                                    {process.env.REACT_APP_CURRENCY} {row.gst_selling_price}
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span className="price">Free</span>
                                </>
                              )}
                              {isItemInCart(row.id) ? (
                                <Button aria-label="Name" variant="contained" size='small' className="added-to-cart">
                                  <ShoppingCartIcon />
                                  {/* <span>Added to cart</span> */}
                                </Button>
                              ) : (
                                <Button aria-label="Name" onClick={() => addToCart(row.id)} variant="contained" size='small' className="add-to-cart">
                                  <AddShoppingCartIcon />{/* <span>Add To Cart</span> */}
                                </Button>
                              )}
                              {(row.pdf_preview) ?
                                <PreviewIcon
                                  className='pdf-preview-icon'
                                  onClick={() => visiblityPreviewModal(row)}
                                />
                                :
                                ''
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                    )
                  )
                  }
                  <PreviewModalDialog previewModalOpen={previewModalOpen} paper={paper} setPreviewModalOpen={setPreviewModalOpen} />
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : ''
      }
    </>
  );
}
export default BankPo;
