import { useState, useEffect } from "react";
import { Link } from 'react-router-dom'

import { useCart } from '../../contexts/CartContext';
import { useAuth } from '../../contexts/AuthContext';

import axios from 'axios';
import swal from 'sweetalert';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import StarsIcon from '@mui/icons-material/Stars';

import Pagination from "react-js-pagination";
import { saveAs } from 'file-saver';

import {
  Avatar,
  Card,
  Divider,
  CardContent,
  CardHeader,
  Box,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Alert, Button, Rating,
} from "@mui/material";

import RatingReviewModel from './OrderHistory/RatingReview';
import DownloadPreviewModalDialog from './DownloadPreviewModalDialog';
import Moment from 'moment';
import file from './somefile.pdf';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';

// pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/legacy/build/pdf.worker.min.js`;
const options = {
  cMapUrl: '/cmaps/',
};

const Title = styled('span')(() => ({
  fontSize: '1rem',
  fontWeight: '500',
  textTransform: 'capitalize',
}));

const Small = styled('small')(({ bgcolor }) => ({
  width: 50,
  height: 15,
  color: '#fff',
  padding: '2px 8px',
  borderRadius: '4px',
  overflow: 'hidden',
  background: bgcolor,
  boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
}));
const Item = styled("div")(({ theme }) => (
  {
    marginBottom: "5px",
    fontSize: '16px',
    fontWeight: '400',
    color: '#484747'
  }
));
const ItemContent = styled("div")(({ theme }) => (
  {
    marginBottom: "15px",
    fontSize: '16px',
    fontWeight: '600',
  }
));

const StyledTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
  },
  // '& td': { borderBottom: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' },
  '& th': { background: "#003152", color: "#FFF" },
}));

const Container = styled("div")(({ theme }) => (
  {
    margin: "20px",
    [theme.breakpoints.down("sm")]: { margin: "16px" },
    "& .breadcrumb": {
      marginBottom: "30px",
      [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
    },
  }
));
const ContentBox = styled(Box)(() => ({
  height: '100%',
  padding: '32px',
  position: 'relative',
  // background: 'rgba(0, 0, 0, 0.01)',
}));

function StudentDashboard() {

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [tableData, setTableData] = useState(false);
  const [rating_product_id, setRatingProductId] = useState(false);
  const [openRatingModal, setOpenRatingModal] = useState(false);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('profile')));
  const [loading, setLoading] = useState(false);
  const { addToCart, isItemInCart, addToWishlist, removeFormWishlist } = useCart();
  const { isAuthenticated } = useAuth();
  const [previewModalOpen, setPreviewModalOpen] = useState(false);
  const [paper, setPaper] = useState([]);

  useEffect(() => {
    if (rating_product_id) {
      setOpenRatingModal(true);
    } else {
      fetchPurchaseData();
    }
  }, [rating_product_id]);

  /**
   * Fetching data from server.
   */
  const fetchPurchaseData = async (pageNumber = 1) => {
    setLoading(true);
    try {
      await axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/api/dashboard/get-downlodable-order-list', {
          page: pageNumber,
        }).then((response) => {
          var data = response.data;
          if (data !== undefined) {
            if (data.response_code === 200) {
              setTableData(data.data);
            } else if (data.response_code === '401') {
              swal({
                title: "Server not responding!",
                text: 'Please try again!',
                icon: "warning",
              });
            } else {
              swal({
                title: "Server not responding!",
                text: 'Please try again!',
                icon: "warning",
              });
            }
          }
        });
      });
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  }

  /**
   * Download zip file of order.
   */
  const downloadProduct = async (product_order_row_id) => {
    if (product_order_row_id) {
      setLoading(true);
      try {
        await axios.get('/sanctum/csrf-cookie').then(response => {
          axios.post('/api/dashboard/download-zip', {
            row_id: product_order_row_id
          }
            , { responseType: 'blob' }
          )
            .then((...responses) => {
              if (response.data.response_code === "401" || response.data.response_code === 401) {
                console.log(response.data.response_code);
              } else {
                responses.map((res) => (
                  saveAs(res.data, 'Irevize_Purchase_' + product_order_row_id + '.zip')
                ))
              }
            });
        });
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    }
  }

  /**
  * Add Product in to wishlist
  */
  const productAddToWishlist = (paper) => {
    addToWishlist(paper.id);

  }

  /**
   * Remove Product from to wishlist
   */
  const productRemoveFormWishlist = (paper) => {
    removeFormWishlist(paper.id);

  }

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const ratingReviewModal = (product_id) => {
    setRatingProductId(product_id)

  };

  // Product Preview
  const visiblityPreviewModal = (rowData) => {
    setPaper(rowData)
    setPreviewModalOpen(true);
  };


  const [numPages, setNumPages] = useState();
  const [pageNumber, setPageNumber] = useState(1);

  // const onDocumentLoadSuccess= ({ numPages }: { numPages: number }): void {
  //   setNumPages(numPages);
  // }
  return (
    <>
      <div className="pagetitle">
        <h1>Dashboard</h1>
      </div>
      <section className="section dashboard">
        <div>
          {/* <Document file={file}> */}
          {/* <Document file={'./somefile.pdf'}>
            <Page pageNumber={pageNumber} />
          </Document> */}
        </div>
        <Card>
          <CardContent style={{ padding: "20px 20px" }}>
            <div className="row shop_container grid product_list_wrap">
              {tableData && tableData.data.length ? (
                tableData.data.map((row, index) => (
                  <div className="item col-md-3 col-6" key={index}>
                    <div className="product">
                      <Link to={'/paper-detail/' + row.slug} title={row.topic_title}>
                        <div className="product_img">
                          <img src={(row.thumb_nail_image ?? row.thumb_nail_url)} alt={row.topic_title} />
                        </div>
                      </Link>
                      <div className="product_info">
                        <div className='author-info-wrap'>
                          <h6 className='product-subject'>
                            <div className='auther-profile'>
                              {row.profile_image ?
                                <img src={row.profile_image} alt={row.author_name} title={row.author_name} />
                                :
                                <Avatar src="/broken-image.jpg" title={row.author_name} />
                              }
                            </div>
                            {/* to={'/paper-detail/' + row.slug} onClick={() => visiblityPreviewModal(row)} */}
                            <Link to={'/paper-detail/' + row.slug} title={row.topic_title}>
                              {row.subject_name.split(' ')[0]}{(row.subject_name.split(' ')[1]) ? '...' : ''}
                            </Link>
                            <small>{row.author_name}</small>
                          </h6>
                        </div>

                        <h6 className="product_title">
                          <Link to={'/paper-detail/' + row.slug} title={row.topic_title}>
                            {row.topic_title.replace(/(.{70})..+/, "$1…")}
                          </Link>
                        </h6>
                        <div className="rating_wrap">
                          <span className="rating_num">({row.total_review})</span>
                          <Rating className="rating" value={row.avg_rating} name="size-large" size="large" readOnly />
                          {/* <small style={{ float: "right" }}>{Moment(row.created_at).format('DD/MM/YYYY')}</small> */}
                          {/* {isAuthenticated && row.in_wishlist ? (
                            <Button color='secondary' onClick={() => productRemoveFormWishlist(row)} size='small' style={{ float: "right" }}>
                              <FavoriteIcon></FavoriteIcon>
                            </Button>
                          ) : (
                            <Button color='secondary' onClick={() => productAddToWishlist(row)} size='small' style={{ float: "right" }}>
                              <FavoriteBorderIcon></FavoriteBorderIcon>
                            </Button>
                          )} */}
                        </div>
                        <div className="prdt_price">
                          <span className="price">
                            <Link title="Rating and review"
                              to={``}
                              color="warning" variant="outlined" size="small"
                              onClick={() => ratingReviewModal(row.product_id)}
                            >
                              Rating and review
                            </Link>
                          </span>
                        </div>
                        <div className="prdt_price" style={{ width: "100%", height: "100%", display: "grid" }}>
                          <Button onClick={() => downloadProduct(row.id)} color="secondary" variant="contained" className="added-to-cart">
                            Download <DownloadForOfflineIcon />
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                )
                )
              ) : (
                <Alert severity="error">No results match your search criteria — check it out!</Alert>
              )
              }
              {(tableData && tableData.data.length) ? (
                <div className="row">
                  <div className="col-12">
                    <Pagination
                      innerClass="pagination mt-3 justify-content-center pagination_style1"
                      activePage={tableData.current_page}
                      itemsCountPerPage={tableData.per_page}
                      totalItemsCount={tableData.total}
                      pageRangeDisplayed={5}
                      onChange={(pageNumber) => fetchPurchaseData(pageNumber)}
                      itemClass={"page-item"}
                      linkClass={"page-link"}
                    />
                  </div>
                </div>
              ) : ''}
            </div>
            <DownloadPreviewModalDialog previewModalOpen={previewModalOpen} paper={paper} setPreviewModalOpen={setPreviewModalOpen} />
            <RatingReviewModel
              openRatingModal={openRatingModal}
              setOpenRatingModal={setOpenRatingModal}
              product_id={rating_product_id}
              setRatingProductId={setRatingProductId}>
            </RatingReviewModel>
          </CardContent>
        </Card >
      </section >
    </>
  );
}

export default StudentDashboard;
