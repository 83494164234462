import React, { useEffect, useState, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useCart } from '../../../contexts/CartContext';

import { Alert } from '@mui/material';

function MiniCart() {
  const navigate = useNavigate();
  const componentRef = useRef()
  const { cart, totalCartItems, removeItemFromCart } = useCart();

  const [cartOpen, setCartOpen] = useState(false);

  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (cartOpen && componentRef.current && !componentRef.current.contains(e.target)) {
        setCartOpen(false)
      }
    }
    document.addEventListener("mousedown", checkIfClickedOutside)
    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", checkIfClickedOutside)
    }
  }, [cartOpen])

  const _handleCartOpen = (event) => {
    event.preventDefault();
    if (cartOpen) {
      setCartOpen(false);
    } else {
      setCartOpen(true);
    }
  };
  const permalink = (row) => {
    var subject = row.subject_name;
    var subject_slug = subject.replace(' ', '-').toLowerCase();
    if (row.type === 'practice') {
      var url = `/samplepaper/paper-detail/${subject_slug}/${row.slug}`;
    } else {
      var url = `/notes/paper-detail/${subject_slug}/${row.slug}`;
    }
    return url;
  }
  return (
    <>
      {totalCartItems ? (
        <>
          <Link className="nav-link cart_trigger" to="#" onClick={_handleCartOpen}>
            <i className="linearicons-cart"></i><span className="cart_count">{totalCartItems}</span>
          </Link>
          {cartOpen && (
            <div ref={componentRef} className="cart_box dropdown-menu dropdown-menu-right">
              <ul className="cart_list" >
                {cart.cart_items && (
                  cart.cart_items.map((row, index) => (
                    <li key={`cart-summary-${index}`}>
                      <Link to="#" className="item_remove" onClick={() => removeItemFromCart(row.id)} >
                        <i className="ion-close"></i>
                      </Link>
                      <Link to={permalink(row)}>
                        <img src={(row.thumb_nail_image ?? row.thumb_nail_url)} alt={row.topic_title} />
                        <span className="cartindo" title={row.topic_title}>{row.topic_title.replace(/(.{30})..+/, "$1…")}</span>
                      </Link>
                      <span className="cart_quantity">
                        {(row.price) ? (
                          <>
                            {row.quantity} x <span className="cart_amount">
                              <span className="price_symbole">
                                {process.env.REACT_APP_CURRENCY}.</span>
                            </span>{row.gst_selling_price}{(row.price ? '' : 'Free')}
                          </>
                        ) : (
                          <span className="cart_amount">{`Free product`}</span>
                        )}
                      </span>
                    </li>
                  ))
                )}
              </ul>
              {cart.cart_items && (
                <div className="cart_footer">
                  <p className="cart_total"><strong>Subtotal:</strong> <span className="cart_price"> <span className="price_symbole">{process.env.REACT_APP_CURRENCY} </span></span> {cart.cart_total_selling_price} <span style={{ textDecoration: "line-through" }}> {process.env.REACT_APP_CURRENCY} {cart.cart_total_price}</span></p>
                  <p className="cart_buttons">
                    <Link to="/view-cart" onClick={() => setCartOpen(false)} className="btn btn-fill-line rounded-0 view-cart">View Cart</Link>
                    {/* <Link to="/checkout" className="btn btn-fill-out rounded-0 checkout">Checkout</Link> */}
                  </p>
                </div>
              )}
            </div>
          )}

        </>
      ) : (
        <>
          <Link className="nav-link cart_trigger" to="#" onClick={_handleCartOpen}>
            <i className="linearicons-cart"></i><span className="cart_count">0</span>
          </Link>
          {cartOpen && (
            <div ref={componentRef} className="cart_box dropdown-menu dropdown-menu-right">
              <Alert severity="error">Cart is empty — check it out!</Alert>
            </div>
          )}
        </>
      )
      }
    </>
  );
}
export default MiniCart;