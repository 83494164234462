import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import OwlCarousel from 'react-owl-carousel';
import {
    Avatar, CircularProgress, Box, Button, Rating
} from '@mui/material';
import { useCart } from '../../../contexts/CartContext';
import { useAuth } from '../../../contexts/AuthContext';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import FavoriteIcon from '@mui/icons-material/Favorite';
import PreviewIcon from '@mui/icons-material/Preview';
import PreviewModalDialog from '../PreviewModalDialog';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function RelatedLearningMaterial() {
    const { isAuthenticated, checkUserAuth } = useAuth();
    const [relatedLearning, setRelatedLearning] = useState(false);
    const [loading, setLoading] = useState(false);
    const { addToCart, isItemInCart, addToWishlist, removeFormWishlist } = useCart();
    const [paper, setPaper] = useState([]);
    const [previewModalOpen, setPreviewModalOpen] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);
    /**
     * Fetching data from server.
     */
    const fetchData = async () => {
        setLoading(true);
        try {
            axios.post('/api/frontend/get-related-learning')
                .then(response => {
                    if (response.data.response_code === 200) {
                        setRelatedLearning(response.data.data);
                    } else if (response.data.response_code === 401) {

                    } else {

                    }
                    setLoading(false);
                })
                .catch(function (error) {
                    setLoading(false);
                    console.log(error);
                });
        } catch (e) {
            setLoading(false);
        }
    }

    /**
     * Add Product in to wishlist
     */
    const productAddToWishlist = (paper) => {
        if (checkUserAuth()) {
            addToWishlist(paper.id);
            const newRelatedLearning = relatedLearning.map((item, i) => {
                if (paper.id === item.id) {
                    return { ...item, ['in_wishlist']: 1 };
                } else {
                    return item;
                }
            });
            setRelatedLearning(newRelatedLearning);
        } else {
            swal({
                text: 'Please logged out from current login and Signin or Signup as student or user for Add To Wishlist!',
                icon: 'warning',
                buttons: false,
                timer: 2000
            });
            return false;
        }
    }

    /**
     * Remove Product from to wishlist
     */
    const productRemoveFormWishlist = (paper) => {
        removeFormWishlist(paper.id);
        const newRelatedLearning = relatedLearning.map((item, i) => {
            if (paper.id === item.id) {
                return { ...item, ['in_wishlist']: 0 };
            } else {
                return item;
            }
        });
        setRelatedLearning(newRelatedLearning);
    }
    const options = {
        autoplay: false,
        loop: false,
        margin: 20,
        nav: false,
        dots: false,
        responsiveClass: false,
        // navText: ['<i class="ion-ios-arrow-left"></i>', '<i class="ion-ios-arrow-right"></i>'],
        smartSpeed: 1000,
        responsive: {
            0: {
                items: 1,
                dots: true,
                nav: false,
            },
            700: {
                items: 2,
                nav: true,
                dots: false,
            },
            1000: {
                items: 5,
                nav: false,
                dots: true,
            }
        },
    };

    const permalink = (row) => {
        var subject = row.subject_name;
        var subject_slug = (row.subject_slug) ? row.subject_slug : subject.replace(' ', '-').toLowerCase();
        if (row.type === 'practice') {
            var url = `/samplepaper/paper-detail/${subject_slug}/${row.slug}`;
        } else {
            var url = `/notes/paper-detail/${subject_slug}/${row.slug}`;
        }
        return url;
    }

    // Product Preview
    const visiblityPreviewModal = (rowData) => {
        setPaper(rowData)
        setPreviewModalOpen(true);
    };

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <h3 className="btn-shine mt-30 font25 mb-20">Related Learning Material/ Practice Test</h3>
                    <div className="product_slider procolt product_list_wrap">
                        {relatedLearning && (
                            <OwlCarousel className='owl-theme'
                                {...options}
                            >
                                {
                                    relatedLearning.map((row, index) => (
                                        <div className="item" key={index}>
                                            <div className="product">
                                                <Link to={permalink(row)} title={row.topic_title}>
                                                    <div className="product_img">
                                                        <img src={(row.thumb_nail_image ?? row.thumb_nail_url)} alt={row.topic_title} />
                                                    </div>
                                                </Link>
                                                <div className="product_info">
                                                    <div className='author-info-wrap'>
                                                        <h6 className='product-subject'>
                                                            <div className='auther-profile'>
                                                                {row.profile_image ?
                                                                    <img src={row.profile_image} alt={row.author_name} />
                                                                    :
                                                                    <Avatar src="/broken-image.jpg" />
                                                                }
                                                            </div>
                                                            <Link to={permalink(row)} title={row.subject_name}>
                                                                {row.subject_name.split(' ')[0]}{(row.subject_name.split(' ')[1]) ? '...' : ''}
                                                            </Link>
                                                            <small>{row.author_name}</small>
                                                        </h6>
                                                    </div>
                                                    <h6 className="product_title">
                                                        <Link to={permalink(row)} title={row.topic_title}>
                                                            {row.topic_title.replace(/(.{50})..+/, "$1…")}
                                                        </Link>
                                                    </h6>
                                                    {/* <small>{row.author_name}</small> */}
                                                    <div className="rating_wrap">
                                                        <span className="rating_num">({row.total_review})</span>
                                                        <Rating className="rating" value={row.avg_rating} name="size-large" size="large" readOnly />
                                                        {row.in_wishlist ? (
                                                            <Button color='secondary' aria-label="Name" onClick={() => productRemoveFormWishlist(row)} size='small' style={{ float: "right" }}>
                                                                <FavoriteIcon></FavoriteIcon>
                                                            </Button>
                                                        ) : (
                                                            <Button color='secondary' aria-label="Name" onClick={() => productAddToWishlist(row)} size='small' style={{ float: "right" }}>
                                                                <FavoriteBorderIcon></FavoriteBorderIcon>
                                                            </Button>
                                                        )}
                                                    </div>
                                                    <div className="prdt_price">
                                                        <div>
                                                            <small className="price1" style={{ textDecoration: "line-through" }}>{process.env.REACT_APP_CURRENCY} {row.gst_price}</small>
                                                            <small>{row.discount_percentage ? ` ( -${row.discount_percentage}%)` : ' ( -0%)'}</small>
                                                            {/* <small>{` (Inclusive GST)`}</small> */}
                                                        </div>
                                                        {(row.price) ? (
                                                            <>
                                                                <span className="price">
                                                                    {process.env.REACT_APP_CURRENCY} {row.gst_selling_price}
                                                                </span>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <span className="price">Free</span>
                                                            </>
                                                        )}
                                                        {isItemInCart(row.id) ? (
                                                            <Button aria-label="Name" variant="contained" size='small' className="added-to-cart">
                                                                <ShoppingCartIcon />{/* <span>Added to cart</span> */}
                                                            </Button>
                                                        ) : (
                                                            <Button aria-label="Name" onClick={() => addToCart(row.id)} variant="contained" size='small' className="add-to-cart">
                                                                <AddShoppingCartIcon />{/* <span>Add To Cart</span> */}
                                                            </Button>
                                                        )}
                                                        {(row.pdf_preview) ?
                                                            <PreviewIcon
                                                                className='pdf-preview-icon'
                                                                onClick={() => visiblityPreviewModal(row)}
                                                            />
                                                            :
                                                            ''
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    )
                                }
                            </OwlCarousel>
                        )}
                        <PreviewModalDialog previewModalOpen={previewModalOpen} paper={paper} setPreviewModalOpen={setPreviewModalOpen} />
                    </div>
                </div>
            </div>
        </>
    );
}
export default RelatedLearningMaterial;
