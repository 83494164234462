import * as React from 'react';
import { Link } from 'react-router-dom';
import {
  Dialog, Grid,
  DialogTitle, DialogContent, DialogActions,
  IconButton,
  Button,
  styled,
  Rating, Avatar
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import FavoriteBorderIcon from '@mui/icons-material/Favorite';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiPaper-root': {
    maxWidth: ' 80%',
    width: '890%;'
  },

}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function PreviewModalDialog({ previewModalOpen, paper, setPreviewModalOpen }) {
  const handleClose = () => {
    setPreviewModalOpen(false);
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={previewModalOpen}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Preview
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <div className="row">
            <div className="col-lg-8">
              <div className="row align-items-center prod-detail">
                <div className="col-12">
                  <h1 className="btn-shine">{paper.topic_title}</h1>
                  {/* <h4>{paper.sub_topic_title}</h4> */}
                  <ul className="rating-prolist">
                    <li>
                      <span>{paper.total_review}</span>
                      <Rating className="rating" value={paper.avg_rating}
                        name="size-medium" size="medium" readOnly />
                    </li>
                    <li>{paper.downloads} - Downloads</li>
                  </ul>
                  <p>{paper.description}</p>

                  {paper.what_you_will_learn_keywords ? (
                    <>
                      <h3 className="btn-shine mt-30">What you will learn</h3>
                      <ul className="check-prolist">
                        {paper.what_you_will_learn_keywords.split(',').map((keyword) =>
                          <li>{keyword}</li>
                        )}
                      </ul>
                    </>
                  ) : ''}

                  {paper.about_me ? (
                    <div className="mt-30 prodbgbox">
                      <h3 className="btn-shine mt-30 text-center1">Instructors</h3>
                      <br />
                      <Grid container>
                        <Grid item sm={6} xs={12} lg={3}>
                          <div className='auther-profile1'>
                            {paper.profile_image ?
                              <img src={paper.profile_image} title={paper.author_name} alt={paper.author_name} style={{ width: "170px", borderRadius: "50%", border: "1px solid #CCC" }} />
                              :
                              <Avatar src="/broken-image.jpg" title={paper.author_name} sx={{ width: 170, height: 170 }} />
                            }
                          </div>
                        </Grid>
                        <Grid item sm={6} xs={12} lg={8}>
                          <div className='instruct-detail'>
                            <p> <strong>Professional summary :</strong></p>
                            <p>Name : <strong>{paper.author_name}</strong></p>
                            <p>Organization Type : <strong>{paper.ot_name}</strong></p>
                            <p>Education/Qualification : <strong>{paper.qualification}</strong></p>
                            <p>Experience as a Teacher : <strong>{paper.experience_as_teacher} Years</strong></p>
                            {/* <p>Students : <strong>School</strong></p> */}
                          </div>
                        </Grid>
                      </Grid>
                      <br />
                      <p>{paper.about_me}</p>
                      {paper.key_strengths ? (
                        <>
                          <h4>Key Strengths</h4>
                          <ul className="prodbgbox mt-10">
                            {paper.key_strengths.split(',').map((keyword) =>
                              <li>{keyword}</li>
                            )}
                          </ul>
                        </>
                      ) : ''}
                    </div>
                  ) : ''}
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="sidebar prodimgbar">
                <div className="widget">
                  <img src={(paper.thumb_nail_image ?? paper.thumb_nail_url)} alt={paper.topic_title} width={'100%'} />
                  <div className="product_info">
                    <div className='author-info-wrap'>
                      <h6 className='product-subject'>
                        <div className='auther-profile'>
                          {paper.profile_image ?
                            <img src={paper.profile_image} alt={paper.author_name} />
                            :
                            <Avatar src="/broken-image.jpg" />
                          }
                        </div>
                        {paper.subject_name}
                        <small>{paper.author_name}</small>
                      </h6>
                    </div>
                  </div>
                  <div className="prodcontbox">
                    <h3>
                      <div>
                        <small className="price1" style={{ textDecoration: "line-through" }}>
                          {process.env.REACT_APP_CURRENCY} {paper.gst_price}
                        </small>
                        <small>{paper.discount_percentage ? ` ( -${paper.discount_percentage}%)` : ' ( -0%)'}</small>
                        <small>{` (Inclusive GST)`}</small>
                      </div>
                      {process.env.REACT_APP_CURRENCY} {paper.gst_selling_price}
                      <Button color='secondary' size='large' style={{ float: "right" }}>
                        <FavoriteBorderIcon></FavoriteBorderIcon>
                      </Button>
                    </h3>
                    <Link to="#" className="btn btn-fill-out rounded-0 checkout display-block">Add to Cart</Link>
                    <Link to="#" className="btn btn-fill-line rounded-0 view-cart display-block mt-10 ml-0">Buy Now</Link>
                    <h6>{(paper.type === 'learning') ? 'Learning material' : 'Practice Test'} Includes</h6>
                    <ul>
                      <li>
                        <img src={`${process.env.PUBLIC_URL}/assets/images/board.png`} />
                        <strong>Board/University/Entrance Exam</strong>
                        <span>
                          {paper.board_id
                            ? `${paper.board_name} Board`
                            : <>
                              {paper.university_id
                                ? paper.university_name
                                : <>
                                  {paper.entrance_exam_id
                                    ? paper.entrance_exam_name
                                    : <>
                                      {paper.competitive_exam_id
                                        ? paper.competitive_exam_name
                                        : <>
                                        </>
                                      }
                                    </>
                                  }
                                </>
                              }
                            </>
                          }
                        </span>
                      </li>
                      <li>
                        <img src={`${process.env.PUBLIC_URL}/assets/images/level-m.png`} />
                        <strong>Class</strong>
                        <span>{paper.class_name}</span>
                      </li>
                      {/* <li>
                        <img src={`${process.env.PUBLIC_URL}/assets/images/level-m.png`} />
                        <strong>Difficulty Level</strong>
                        <span>{paper.paper_level}</span>
                      </li> */}
                    </ul>
                  </div>
                </div>

              </div>
              {/* <div className="lgtctr"><Link to="#">Find out more</Link> about Latest courses</div> */}
            </div>
          </div>
        </DialogContent >
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog >
    </div >
  );
}