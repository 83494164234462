import { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import axios from 'axios';
import {
  Card,
  Divider,
  CardContent,
  CardHeader,
  Box,
  Grid,
  Button,
  styled,
  useTheme,
  Table, TableHead, TableRow, TableCell, TableBody
} from "@mui/material";
import Moment from 'moment';

const Item = styled("div")(({ theme }) => (
  {
    marginBottom: "5px",
    fontSize: '16px',
    fontWeight: '400',
    color: '#484747'
  }
));
const ItemContent = styled("div")(({ theme }) => (
  {
    marginBottom: "15px",
    fontSize: '16px',
    fontWeight: '600',
  }
));

const Container = styled("div")(({ theme }) => (
  {
    margin: "30px",
    [theme.breakpoints.down("sm")]: { margin: "16px" },
    "& .breadcrumb": {
      marginBottom: "30px",
      [theme.breakpoints.down("sm")]: { marginBottom: "16px" },
    },
  }
));

const ContentBox = styled(Box)(() => ({
  height: '100%',
  padding: '32px',
  position: 'relative',
  // background: 'rgba(0, 0, 0, 0.01)',
}));

// form field validation schema
const validationSchema = Yup.object().shape({
  status: Yup.string()
    .required('User Accton is required!'),
});

const Small = styled('small')(({ bgcolor }) => ({
  width: 50,
  height: 15,
  color: '#fff',
  padding: '5px 8px',
  borderRadius: '4px',
  overflow: 'hidden',
  background: bgcolor,
  boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
  cursor: 'pointer'
}));

const OrderDetails = () => {
  const { palette } = useTheme();
  const bgError = palette.error.main;
  const bgPrimary = palette.primary.main;
  const bgSecondary = palette.secondary.main;
  const bgSuccess = palette.success.main;
  const bgSuccessLight = palette.success.light;
  const bgSecondaryDark = palette.secondary.dark;

  const { order_id } = useParams();
  const theme = useTheme();
  const navigate = useNavigate();
  const [order, setOrder] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  /**
   * Fetching data from server.
   */
  const fetchData = async () => {
    setLoading(true);
    try {
      await axios.get('/sanctum/csrf-cookie').then(response => {
        axios.post('/api/dashboard/get-order-details', {
          order_id: order_id
        })
          .then(response => {
            if (response.data.response_code === 200) {
              setOrder(response.data.data);
            } else if (response.data.response_code === 401) {

            } else {

            }
            setLoading(false);
          })
          .catch(function (error) {
            setLoading(false);
            console.error(error);
          });
      });
    } catch (e) {
      setLoading(false);
    }
  }

  return (
    <>
      <div className="pagetitle">
        <h1>Order Details</h1>
      </div>
      <Card title="Order Details">
        <ContentBox>
          <CardHeader
            title="Order Details"
            titleTypographyProps={{
              variant: "h4",
              fontSize: '18px',
              fontWeight: '600',
              // textTransform: 'uppercase',
              color: theme.palette.primary.main,

            }}
            action={
              <>
                <Button title="Back" component={Link}
                  to={`/dashboard/order/order-history`}
                  color="inherit" variant="outlined" size="small" sx={{ m: .5 }}>
                  Back
                </Button>
              </>
            }
          />
          <Divider />
          <CardContent>
            <Box width="100%" overflow="auto">
              {order && (
                <>
                  <Grid container>
                    <Grid item sm={6} xs={12} lg={3}>
                      <Item>Order Id</Item>
                      <ItemContent>{order.order_id}</ItemContent>
                    </Grid>
                    <Grid item sm={6} xs={12} lg={3}>
                      <Item>Transaction Id</Item>
                      <ItemContent>
                        {(order.total_selling_price) ? order.transaction_id : (
                          <>
                            <Small bgcolor={bgSuccess} sx={{ m: 1 }} title="Free">Free</Small>
                          </>
                        )}
                      </ItemContent>
                    </Grid>
                    <Grid item sm={6} xs={12} lg={3}>
                      <Item>Payment Status</Item>
                      <ItemContent>
                        {(order.total_selling_price) ? (
                          (() => {
                            switch (order.payment_status) {
                              case 'success':
                                return <Small bgcolor={bgSuccess}>Success</Small>
                              case 'pending':
                                return <Small bgcolor={bgSecondary}>Pending</Small>
                              case 'failed':
                                return <Small bgcolor={bgError}>Failed</Small>
                              default:
                                return null
                            }
                          })()
                        ) : (
                          <>
                            <Small bgcolor={bgSuccess} sx={{ m: 1 }} title="Free">Free</Small>
                          </>
                        )}
                      </ItemContent>
                    </Grid>
                    {/* <Grid item sm={6} xs={12} lg={3}>
                      <Item>Order Status</Item>
                      <ItemContent>
                        {(() => {
                          switch (order.status) {
                            case 'pending':
                              return <Small bgcolor={bgSecondary}>Pending</Small>
                            case 'failed':
                              return <Small bgcolor={bgError}>Failed</Small>
                            case 'cancelled':
                              return <Small bgcolor={bgError}>Cancelled</Small>
                            case 'completed':
                              return <Small bgcolor={bgPrimary}>Completed</Small>
                            case 'processing':
                              return <Small bgcolor={bgSuccessLight}>Processing</Small>
                            case 'ready_to_ship':
                              return <Small bgcolor={bgSuccessLight}>Ready To Ship</Small>
                            case 'shipped':
                              return <Small bgcolor={bgSuccessLight}>Shipped</Small>
                            case 'delivered':
                              return <Small bgcolor={bgSuccess}>Delivered</Small>
                            case 'return':
                              return <Small bgcolor={bgSecondaryDark}>Return</Small>
                            case 'refund':
                              return <Small bgcolor={bgSecondaryDark}>Refund</Small>
                            default:
                              return null
                          }
                        })()}
                      </ItemContent>
                    </Grid> */}
                    <Grid item sm={6} xs={12} lg={3}>
                      <Item>Total Price</Item>
                      <ItemContent>{process.env.REACT_APP_CURRENCY} {order.total_price}</ItemContent>
                    </Grid>
                    <Grid item sm={6} xs={12} lg={3}>
                      <Item>Total Selling Price</Item>
                      <ItemContent>{process.env.REACT_APP_CURRENCY} {order.total_selling_price}</ItemContent>
                    </Grid>
                    <Grid item sm={6} xs={12} lg={3}>
                      <Item>Order Date</Item>
                      <ItemContent>{Moment(order.created_at).format('DD/MM/YYYY')}</ItemContent>
                    </Grid>
                  </Grid>
                  <br />
                  <br />
                  <Divider />
                  <br />
                  <br />
                  <h4 style={{ color: theme.palette.primary.main, fontSize: '18px', }}>Order Summary</h4>
                  <Grid container>
                    <Grid item sm={12} xs={12} lg={12}>
                      <Box overflow="auto">
                        <Table>
                          <TableHead>
                            <TableRow>
                              <TableCell align="center">Product </TableCell>
                              <TableCell align="center">Author</TableCell>
                              <TableCell align="center">Total Price</TableCell>
                              <TableCell align="center">Total Selling Price</TableCell>
                              {/* <TableCell align="center">Status</TableCell> */}
                              {/* <TableCell align="center">Action</TableCell> */}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {order.order_items && (
                              order.order_items.map((row, index) => (
                                <TableRow key={'order-summary-' + index}>
                                  <TableCell align="center">
                                    <a className="hover_effect1" title={row.product_name} href={`/product/details/${row.id}`}>
                                      <img className='os-list-img' src={(row.thumb_nail_image ?? row.thumb_nail_url)} alt={row.topic_title} width={'100px'} />
                                    </a>
                                    <br />
                                    <strong>{row.topic_title}</strong>
                                  </TableCell>
                                  <TableCell align="center">{row.author_name}</TableCell>
                                  <TableCell align="center">{process.env.REACT_APP_CURRENCY} {row.gst_total_price}</TableCell>
                                  <TableCell align="center">{process.env.REACT_APP_CURRENCY} {row.gst_total_selling_price}</TableCell>
                                  {/* <TableCell align="center">
                                    {(() => {
                                      switch (row.status) {
                                        case 'pending':
                                          return <Small bgcolor={bgSecondary}>Pending</Small>
                                        case 'failed':
                                          return <Small bgcolor={bgError}>Failed</Small>
                                        case 'cancelled':
                                          return <Small bgcolor={bgError}>Cancelled</Small>
                                        case 'completed':
                                          return <Small bgcolor={bgPrimary}>Completed</Small>
                                        case 'processing':
                                          return <Small bgcolor={bgSuccessLight}>Processing</Small>
                                        case 'return':
                                          return <Small bgcolor={bgSecondaryDark}>Return</Small>
                                        case 'refund':
                                          return <Small bgcolor={bgSecondaryDark}>Refund</Small>
                                        default:
                                          return null
                                      }
                                    })()}
                                  </TableCell> */}
                                </TableRow >
                              ))
                            )}
                          </TableBody>
                        </Table>
                        <div className='order_summary os-left' style={{ width: "350px", float: "right", padding: "20px" }}>
                          <Table sx={{ border: "0px" }} aria-label="simple table" key={'price-detail'}>
                            <TableBody sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableRow>
                                <TableCell align="left"><ItemContent>Total Price</ItemContent></TableCell>
                                <TableCell align="right"><ItemContent>{process.env.REACT_APP_CURRENCY}. {order.total_price}</ItemContent></TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell align="left"><ItemContent>Total Selling Price</ItemContent></TableCell>
                                <TableCell align="right"><ItemContent>{process.env.REACT_APP_CURRENCY}. {(order.total_selling_price) ?? 0.0}</ItemContent></TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell align="left" colSpan={2}><Divider /></TableCell>
                              </TableRow>
                              <TableRow className='total-payable'>
                                <TableCell align="left"><ItemContent>Total Payable</ItemContent></TableCell>
                                <TableCell align="right">
                                  <ItemContent>
                                    <span className='price'>
                                      {(order.total_selling_price) ? (
                                        <>
                                          {process.env.REACT_APP_CURRENCY}. {order.total_selling_price}
                                        </>
                                      ) : (
                                        <>
                                          <Small bgcolor={bgSuccess} sx={{ m: 1 }} title="Free">Free</Small>
                                        </>
                                      )}
                                    </span>
                                  </ItemContent>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </div>
                      </Box>
                    </Grid>
                  </Grid>
                </>
              )}
            </Box>
          </CardContent>
        </ContentBox>
      </Card>
    </>

  );
};

export default OrderDetails;
