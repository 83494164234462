import * as React from 'react';
import {
  Divider,
  CardContent,
  Box, Grid, Dialog,
  DialogTitle, DialogContent, DialogActions,
  IconButton,
  Button,
  styled,
  Alert, useTheme,
} from "@mui/material";
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
import CloseIcon from '@mui/icons-material/Close';
import Moment from 'moment';

const Small = styled('small')(({ bgcolor }) => ({
  width: 50,
  height: 15,
  color: '#fff',
  padding: '2px 8px',
  borderRadius: '4px',
  overflow: 'hidden',
  background: bgcolor,
  margin: "0px !important",
  boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
}));
const Item = styled("div")(({ theme }) => (
  {
    marginBottom: "5px",
    fontSize: '16px',
    fontWeight: '400',
    color: '#484747'
  }
));
const ItemContent = styled("div")(({ theme }) => (
  {
    marginBottom: "15px",
    fontSize: '16px',
    fontWeight: '600',
  }
));
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
  '& .MuiPaper-root': {
    maxWidth: ' 80%',
    width: '890%;'
  },

}));

export interface DialogTitleProps {
  id: string;
  children?: React.ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

export default function DetailModalDialog({ detailModalOpen, paper, setDetailModalOpen }) {
  const { palette } = useTheme();
  const bgError = palette.error.main;
  const bgSuccess = palette.success.main;
  const bgWarning = palette.warning.main;
  const handleClose = () => {
    setDetailModalOpen(false);
  };
  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={detailModalOpen}
      >
        <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
          Details
        </BootstrapDialogTitle>
        <DialogContent dividers style={{ height: '550px', overflowY: 'auto' }}>
          {/* <Card> */}
          <CardContent>
            <Box width="100%" overflow="auto">
              {paper && (
                <>
                  <Grid container>
                    <Grid item sm={12} xs={12} lg={8}>
                      <Item>Topic/Title</Item>
                      <ItemContent>{paper.topic_title}</ItemContent>
                    </Grid>
                    <Grid item sm={12} xs={12} lg={4}>
                      <Item>Status</Item>
                      <ItemContent>{(() => {
                        switch (paper.status) {
                          case 1:
                            return <Small bgcolor={bgSuccess} sx={{ m: 1 }} title="Approved">Approved</Small>
                          case 0:
                            return <Small bgcolor={bgWarning} variant="contained" size="small" sx={{ m: 1 }}>Pending</Small>
                          case 2:
                            return <Small bgcolor={bgError} variant="contained" size="small" sx={{ m: 1 }}>Rejected</Small>
                          case 3:
                            return <Small bgcolor={'#9c1515'} variant="contained" size="small" sx={{ m: 1 }}>Draft</Small>
                          default:
                            return null
                        }
                      })()}
                      </ItemContent>
                    </Grid>
                    <Grid item sm={12} xs={12} lg={12}>
                      <Divider />
                      <br />
                    </Grid>

                    <Grid item sm={12} xs={12} lg={4}>
                      <Item>School/College/University/Institute</Item>
                      <ItemContent>{paper.organization_type_name}</ItemContent>
                    </Grid>

                    {paper.organization_type_id && (paper.organization_type_id == 1) && (
                      <>
                        <Grid item sm={12} xs={12} lg={4}>
                          <Item>Board</Item>
                          <ItemContent>{paper.board_name}</ItemContent>
                        </Grid>
                        <Grid item sm={12} xs={12} lg={4}>
                          <Item>Class</Item>
                          <ItemContent>{paper.class_name}</ItemContent>
                        </Grid>
                      </>
                    )}
                    {paper.organization_type_id && (paper.organization_type_id !== 1) && (
                      <>
                        <Grid item sm={6} xs={12} lg={4}>
                          <Item>Year</Item>
                          <ItemContent>{paper.year}</ItemContent>
                        </Grid>
                      </>
                    )}
                    {paper.organization_type_id && (paper.organization_type_id == 2) && (
                      <>
                        <Grid item sm={6} xs={12} lg={4}>
                          <Item>University</Item>
                          <ItemContent>{paper.university_name}</ItemContent>
                        </Grid>
                        <Grid item sm={6} xs={12} lg={4}>
                          <Item>Course</Item>
                          <ItemContent>{paper.course_name}</ItemContent>
                        </Grid>
                      </>
                    )}
                    {paper.organization_type_id && (paper.organization_type_id == 3) && (
                      <>
                        <Grid item sm={6} xs={12} lg={4}>
                          <Item>Entrance Exam</Item>
                          <ItemContent>{paper.entrance_exam_name}</ItemContent>
                        </Grid>
                      </>
                    )}
                    {paper.organization_type_id && (paper.organization_type_id == 4) && (
                      <>
                        <Grid item sm={6} xs={12} lg={4}>
                          <Item>Competitive Exam</Item>
                          <ItemContent>{paper.competitive_exam_name}</ItemContent>
                        </Grid>
                      </>
                    )}
                    <Grid item sm={12} xs={12} lg={4}>
                      <Item>Subject</Item>
                      <ItemContent>{paper.subject_name}</ItemContent>
                    </Grid>

                    {/* <Grid item sm={6} xs={12} lg={4}>
                      <Item>No. of Questions</Item>
                      <ItemContent>{paper.number_of_questions}</ItemContent>
                    </Grid>
                    <Grid item sm={6} xs={12} lg={4}>
                      <Item>Difficulty Level</Item>
                      <ItemContent>{paper.difficulty_level}</ItemContent>
                    </Grid> */}
                    <Grid item sm={6} xs={12} lg={4}>
                      <Item title="Submitted Date">Submitted Date</Item>
                      <ItemContent>{Moment(paper.created_at).format('DD/MM/YYYY')}</ItemContent>
                    </Grid>
                    <Grid item sm={6} xs={12} lg={4}>
                      <Item>Updated Date</Item>
                      <ItemContent>{Moment(paper.updated_at).format('DD/MM/YYYY')}</ItemContent>
                    </Grid>
                  </Grid>
                  <br />
                  <Divider />
                  <br />
                  <Grid container>
                    <Grid item sm={6} xs={12} lg={2}>
                      <Item>Listing Price</Item>
                      <ItemContent>{paper.price}</ItemContent>
                    </Grid>
                    <Grid item sm={6} xs={12} lg={2}>
                      <Item>With GST Listing Price</Item>
                      <ItemContent>{paper.gst_price}</ItemContent>
                    </Grid>
                    <Grid item sm={6} xs={12} lg={2}>
                      <Item>Discount(%)</Item>
                      <ItemContent>{paper.discount_percentage}</ItemContent>
                    </Grid>
                    <Grid item sm={6} xs={12} lg={2}>
                      <Item title="Selling Price">Selling Price</Item>
                      <ItemContent>{paper.selling_price}</ItemContent>
                    </Grid>
                    <Grid item sm={6} xs={12} lg={2}>
                      <Item title="Selling Price">With GST Selling Price</Item>
                      <ItemContent>{paper.gst_selling_price}</ItemContent>
                    </Grid>

                  </Grid>
                  <Divider />
                  <br />
                  <Grid container>
                    <Grid item sm={6} xs={12} lg={4}>
                      <Item>Learning Material</Item>
                      <ItemContent style={{ padding: "46px 3px 3px 30px" }}>
                        {(paper.learning_material_file_url) && (
                          <>
                            <a title='Test Paper' href={paper.learning_material_file_url} target='_blank'>
                              <SystemUpdateAltIcon />
                            </a>
                          </>
                        )}

                      </ItemContent>
                    </Grid>
                    {/* <Grid item sm={6} xs={12} lg={4}>
                      <Item>Paper Thumbnail</Item>
                      <ItemContent><img src={paper.thumb_nail_url} style={{ width: '100px' }} /></ItemContent>
                    </Grid> */}

                    <Grid item sm={12} xs={12} lg={12}>
                      <Item>Description</Item>
                      <ItemContent>{paper.description}</ItemContent>
                    </Grid>
                    <br />
                    <Grid item sm={12} xs={12} lg={12}>
                      <Item>What you will learn keywords</Item>
                      <ItemContent>{paper.what_you_will_learn_keywords}</ItemContent>
                    </Grid>
                    <br />
                    <Grid item sm={12} xs={12} lg={12}>
                      <Item>Original Owner </Item>
                      <ItemContent>
                        <Alert severity="success" color="success">
                          I am the original owner of this material!
                        </Alert>
                      </ItemContent>
                    </Grid>
                  </Grid>
                </>
              )}
            </Box>
          </CardContent>
          {/* </Card> */}
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose}>
            Close
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}