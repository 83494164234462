import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import swal from 'sweetalert';
import {
  CircularProgress, Box, Button, Grid, Card, CardHeader, Divider, CardContent,
  styled, Table, TableBody, TableRow, TableCell, Rating, Alert
} from '@mui/material';
import { useCart } from '../../../contexts/CartContext';


const StyledTable = styled(Table)(() => ({
  minWidth: 400,
  whiteSpace: 'pre',
  '& small': {
    width: 50,
    height: 15,
    borderRadius: 500,
    boxShadow: '0 0 2px 0 rgba(0, 0, 0, 0.12), 0 2px 2px 0 rgba(0, 0, 0, 0.24)',
  },
  // '& td': { borderBottom: 'none' },
  '& td:first-of-type': { paddingLeft: '16px !important' },
  '& th': { background: "#003152", color: "#FFF" },
}));

const permalink = (row) => {
  var subject = row.subject_name;
  var subject_slug = (row.subject_slug) ? row.subject_slug : subject.replace(' ', '-').toLowerCase();
  if (row.type === 'practice') {
    var url = `/samplepaper/paper-detail/${subject_slug}/${row.slug}`;
  } else {
    var url = `/notes/paper-detail/${subject_slug}/${row.slug}`;
  }
  return url;
}
function ViewCart() {
  const [searchPapers, setSearchPapers] = useState(false);
  const [loading, setLoading] = useState(false);
  const { cart, totalCartItems, removeItemFromCart, cartCheckOut } = useCart();

  return (
    <>
      <div className="breadcrumb_section  page-title-mini">
        <div className="container">
          <div className="row">

            <div className="col-md-6">
              <ol className="breadcrumb ">
                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                <li className="breadcrumb-item active">Cart</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      <div className="main_content">
        <div className="section">
          <div className="container">
            <h1 className="btn-shine">Cart</h1>
            {totalCartItems ? (
              <Grid container item rowSpacing={2} columnSpacing={4}>
                <Grid item lg={8} sm={12} xs={12}>
                  <Card>
                    <CardHeader
                      title={`${(totalCartItems) ?? '0'} items in Cart`}
                      titleTypographyProps={{
                        variant: "h2",
                        fontSize: '16px',
                        fontWeight: '600',
                      }}
                      sx={{ background: "#f4f4f4" }} />
                    <Divider />
                    <CardContent style={{ padding: "0px" }}>
                      <StyledTable>
                        <TableBody>
                          {cart && cart.cart_items && (
                            cart.cart_items.map((row, index) => (
                              <TableRow key={`tbl-cart-${index}`}>
                                <TableCell align="left" sx={{ width: "120px" }}>
                                  <Link to={permalink(row)}>
                                    <img src={(row.thumb_nail_image ?? row.thumb_nail_url)} alt={row.topic_title} />
                                  </Link>
                                </TableCell>
                                <TableCell align="left" title={row.topic_title}>
                                  <h6 className="product_title">
                                    <Link to={permalink(row)} title={row.topic_title}>
                                      {row.topic_title.replace(/(.{50})..+/, "$1…")}
                                    </Link>
                                  </h6>
                                  <span>{row.author_name}</span>
                                  <div className="rating_wrap">
                                    <span className="product_title">
                                      {(() => {
                                        switch (row.organization_type_id) {
                                          case (1 || '1'):
                                            return `${row.class_name}`;
                                          case (2 || '2'):
                                            return `${row.university_name}`;
                                          case (3 || '3'):
                                            return `${row.entrance_exam_name}`;
                                          case (4 || '4'):
                                            return `${row.competitive_exam_name}`;
                                          default:
                                            return null;
                                        }
                                      })()}
                                    </span>
                                    {/* <span className="rating_num">({row.avg_rating})</span>
                                    <Rating className="rating" value={row.avg_rating} name="size-large" size="large" readOnly />
                                    <Button disableElevation sx={{ color: "rgb(0 49 82) !important", textTransform: "capitalize" }}>
                                      ({row.total_review ?? '0'} Rating)
                                    </Button>
                                     */}
                                  </div>
                                </TableCell>
                                <TableCell align="right">
                                  <Button title='Remove form Cart' onClick={() => removeItemFromCart(row.id)} sx={{ color: "rgb(0 49 82) !important", textTransform: "capitalize" }}>Remove</Button>
                                  <br />
                                  {/* <Button title='Add to Wishlist' onClick={() => removeItemFromCart(row.id)} sx={{ color: "rgb(0 49 82) !important", textTransform: "capitalize" }}> Save For Later</Button> */}

                                </TableCell>
                                <TableCell align="center" sx={{ width: "60px" }}>
                                  {(row.gst_price) ? (
                                    <>
                                      <span className="price" >
                                        {process.env.REACT_APP_CURRENCY} {row.gst_total_selling_price}
                                      </span>
                                      <br />
                                      <span className="price cart-price-dsc" >
                                        {process.env.REACT_APP_CURRENCY} {row.gst_total_price}
                                      </span>
                                      <span>{row.discount_percentage ? `(${row.discount_percentage}%)` : ''}</span>
                                    </>
                                  ) : 'Free'}
                                </TableCell>
                              </TableRow>
                            ))
                          )}
                        </TableBody >
                      </StyledTable>

                    </CardContent>
                  </Card>
                </Grid>
                <Grid item lg={4} sm={12} xs={12}>
                  <Card style={{ background: "#F4F4F4" }}>
                    <CardHeader
                      title="Cart Total :"
                      titleTypographyProps={{
                        variant: "h2",
                        fontSize: '18px',
                        fontWeight: '600',
                      }}
                    />
                    <Divider />
                    <CardContent>
                      {cart && (
                        <>
                          <span className="price cart-price">{process.env.REACT_APP_CURRENCY} {cart.cart_total_selling_price}</span>
                          <span className="price cart-price-dsc" >
                            {process.env.REACT_APP_CURRENCY} {cart.cart_total_price}
                          </span>
                          <br />
                          <br />
                          <Link to={'#'} onClick={() => cartCheckOut()} className="btn btn-fill-out rounded-0 checkout display-block">
                            Checkout
                          </Link>
                          {/* <Link to={'/checkout'} className="btn btn-fill-out rounded-0 checkout display-block">
                          Checkout
                        </Link> */}
                        </>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            ) : (
              <>
                <Alert severity="error">Cart is empty — check it out!</Alert>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
              </>
            )}
          </div >
        </div >
      </div >
    </>
  );
}
export default ViewCart;
